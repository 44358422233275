import React from "react";
import { Container, Breadcrumb, Row, Col} from "react-bootstrap";
import {Link} from 'gatsby'
import PageWrapper from "../components/PageWrapper";
import { Section, Title, Post } from "../components/Core";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {Helmet} from "react-helmet";
import {StaticImage} from 'gatsby-plugin-image';

function startabusiness() {
    return (
        <>
        <PageWrapper footerDark>
    	<Helmet>
                <meta charSet="utf-8" />
                <title>Partenaires de BankBank - Comparateur de Banques</title>
                <meta name="description" content="
              Consulter les avis banques et comparez les notations de 16 etablissements français. Chaque fiche reprend l'offre détaillée des banques." />
        </Helmet>
      	<Header />
      	<Section>
      	<Container style={{maxWidth: 1280}}>
		  <Breadcrumb id='breadyTop'>
			<Breadcrumb.Item> <Link to={`/`} style={{color: 'black'}}>Accueil</Link></Breadcrumb.Item>
			<Breadcrumb.Item active id='bready'>Partenaires</Breadcrumb.Item>
      	 </Breadcrumb>
        	<Title id='mobileTitle'>Les Partenaires De BankBank</Title>
			<StaticImage
		  	src='../images/partenaires.png'
			width={1200}
			alt='Banque à distance'
			placeholder='tracedSVG'
			id='pickky'
			/>
			<Post>
			<p>BankBank est un site indépendant de tout établissement financier. Le site a comme objectif de comparer des offres, d’informer et de donner accès à des produits bancaires (comptes courants et cartes bancaires) de la manière la plus objective et sincère possible.
			</p><p>
Tous les jours, l’équipe de BankBank tente de faire veille sur les évolutions des offres et les tarifs du marché mais ce site ne peut que présenter et comparer une sélection de produits et n’effectue pas une comparaison sur la totalité des produits existants. 
</p><p>Ces comparatifs sont notamment représentés sur ces pages :</p>
<ul>
	<li><Link to={'/banque-en-ligne/'}>Comparatif banques en ligne</Link></li>
	<li><Link to={'/neobanque/'}>Comparatif néobanques</Link></li>
	<li><Link to={'/carte-bancaire/'}>Comparatif cartes bancaires</Link></li>
	<li><Link to={'/compte-courant/'}>Comparatif comptes courants</Link></li>
	<li><Link to={'/banque-pro/'}>Comparatif banques pros</Link></li>
</ul>

<p>L’équipe rédige aussi un ensemble d’articles qui a pour but d’informer les internautes.</p>

<p>En contrepartie de cette création de contenue, le site se rémunère en diffusant des publicités présentes sur l’ensemble des pages du site. Cette diffusion peut être automatique ou basée sur des partenariats commerciaux où BankBank agit simplement en tant qu’indicateur.
</p><p>
BankBank a des partenariats directs ou indirectes avec les marques suivantes :</p>

<Row>
<Col><Link to={'/banque/avis-bforbank/'} ><StaticImage src='../images/bforbank-logo.png' width={100} alt='logo bforbank' placeholder='tracedSVG'/></Link></Col>
<Col><Link to={'/banque/avis-bnp-paribas/'} ><StaticImage src='../images/bnpparibas-logo.png' width={150} alt='logo bnp paribas' placeholder='tracedSVG'/></Link></Col>
<Col><Link to={'/banque/avis-boursorama-banque/'} ><StaticImage src='../images/boursorama-banque-logo.png' width={150} alt='logo boursorama banque' placeholder='tracedSVG'/></Link></Col>
<Col><Link to={'/banque/avis-eko/'} ><StaticImage src='../images/eko-logo.png' width={250} alt='logo eko' placeholder='tracedSVG'/></Link></Col>
</Row>

<Row>

<Col><Link to={'/banque/avis-fortuneo-banque/'} ><StaticImage src='../images/fortuneo-banque-logo.png' width={150} alt='logo fortuneo banque' placeholder='tracedSVG'/></Link></Col>
<Col><Link to={'/banque/avis-hello-bank/'} ><StaticImage src='../images/hello-bank-logo.png' width={150} alt='logo hello bank' placeholder='tracedSVG'/></Link></Col>
<Col><Link to={'/banque/avis-hsbc/'} ><StaticImage src='../images/hsbc-logo.png' width={150} alt='logo hsbc' placeholder='tracedSVG'/></Link></Col>
<Col><Link to={'/banque/avis-ing/'} ><StaticImage src='../images/ing-logo.png' width={150} alt='logo ing' placeholder='tracedSVG'/></Link></Col>
</Row>
<Row>

<Col><Link to={'/banque/avis-lcl/'} ><StaticImage src='../images/lcl-logo.png' width={150} alt='logo lcl' placeholder='tracedSVG'/></Link></Col>
<Col><Link to={'/banque/avis-monabanq/'} ><StaticImage src='../images/monabanq-logo.png' width={150} alt='logo monabanq' placeholder='tracedSVG'/></Link></Col>
<Col><Link to={'/banque/avis-n26/'} ><StaticImage src='../images/n26-logo.png' width={150} alt='logo n26' placeholder='tracedSVG'/></Link></Col>
<Col><Link to={'/banque/avis-orange-bank/'} ><StaticImage src='../images/orange-bank-logo.png' width={150} alt='logo boursorama banque' placeholder='tracedSVG'/></Link></Col>
</Row>

<Row>
<Col><Link to={'/banque/avis-revolut'} ><StaticImage src='../images/revolut-logo.png' width={150} alt='logo revolut' placeholder='tracedSVG'/></Link></Col>
<Col><Link to={'/banque/avis-societe-generale/'} ><StaticImage src='../images/societe-generale-logo.png' width={150} alt='logo société générale' placeholder='tracedSVG'/></Link></Col>

</Row>

	</Post>
		</Container>
		</Section>
		<Footer/>
      </PageWrapper>
    </>
    )
}

export default startabusiness
